<template>
    <div class="v2-privacy">
        <div class="title">개인정보 처리방침</div>
        <div class="content">
            <p class="abstract" v-html="privacy.abstract" />
            <div class="privacy-index m-t-16">
                <p
                    v-html="`${item.id}. ${item.title}`"
                    v-for="item in privacy.privacyIndex"
                    :key="`p-idx-${item.id}`"
                />
            </div>
            <div class="specifics m-t-24">
                <div class="specific-item m-b-16" v-for="item in privacy.privacyIndex" :key="`p-item-${item.id}`">
                    <p v-html="`${item.id}. ${item.title}`" />
                    <div class="indent">
                        <div class="item-contents">
                            <div class="item-content" v-for="(content, i) in item.contents" :key="`p-content-${i}`">
                                <div v-if="content.id">
                                    <div class="f-row">
                                        <span
                                            :class="idx ? 'val' : ''"
                                            v-html="idx ? c : `${c}. `"
                                            v-for="(c, idx) in content.id.split('.')"
                                            :key="`p-c-${c}.${idx}-${i}`"
                                        />
                                    </div>
                                    <div v-if="content.ignoreIndent" v-html="content.content" />
                                    <div v-else class="indent" v-html="content.content" />
                                </div>
                                <div v-else>
                                    <p v-html="content.content" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="today m-t-16">
            <span class="m-r-16">계약일: </span>
            <span class="spoqa-f-bold">{{ `${today.y} 년 ${today.m} 월 ${today.d} 일` }}</span>
        </div>
        <div class="name sign-space">
            <div class="left">
                <span>회원성명: </span>
                <span class="m-l-16 spoqa-f-bold" v-html="me.name" />
            </div>
            <SignComponent :signStatus="signStatus" :sign="sign" :idx="2" id="privacy-sign" />
        </div>
    </div>
</template>

<script>
import SignComponent from '@/components/app/SignComponent'

export default {
    name: 'ContractPrivacyV2',
    components: { SignComponent },
    props: {
        sign: String,
        today: Object,
        contractVersion: Object,
        signStatus: Object,
    },
    computed: {
        privacy() {
            return this.contractVersion.privacy
        },
        me() {
            return this.$store.getters.me
        },
    },
}
</script>

<style scoped lang="scss">
.v2-privacy {
    padding-bottom: 40px;
    border-bottom: 1px solid $grey-03;
    margin-bottom: 40px;

    .indent {
        margin-left: 16px;
    }
    .f-row {
        display: flex;

        .num {
            width: 16px;
        }
        .val {
            max-width: 85%;
        }
    }
    .title {
        text-align: center;
        margin-bottom: 12px;
        font-size: 18px;
        @include spoqa-f-bold;
    }
    .item {
        display: flex;
        .num {
            width: 5%;
        }
        .content {
            width: 90%;
        }
    }
    .sign-space {
        display: flex;
        align-items: center;
        margin-top: 16px;
    }
}
</style>
